#aboutus {
  display: block;
  margin-top: 1em;
  
  .about-img {
    height: 60vh;
    margin-inline: 5px;
    margin-top: 1rem;
  }
  .tech-description{
    word-break: break-word;
    display: block;
    margin-inline: auto;
  }
}
