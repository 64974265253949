.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    height: 40px;
    width: 80px;
    margin-inline-end: 10px;
    /* animation: App-logo-spin infinite 20s linear; */
  }

  .nav-btn-contact {
    align-items: flex-start;
  }

}

.App-header {
  /* background-color: #dee7df; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.nav-btn-contact {
  right: 10px;
  position: absolute;
}

header {
  height: 50px;
  flex-shrink: 0;
  background-color: #037cf5;
}

footer {
  background-color: #F3F3F3;
  padding-top: 10px;
  padding-bottom: 0px;
  /* position:relative; */
  bottom: 0;
  width: 100%;
}

main {
  flex: 1 0 auto;
  margin: 20px;
}

.root-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.contact-us-header {
  right: 10px;
  position: absolute;
  margin: 10px;
  margin-inline: auto;
}

.contactus-form {
  display: table-caption;
}

@media screen and (max-width: 600px) {
  .contact-us-header {
    display: flex;
    width: fit-content;
    /* right: 100px; */
    margin-inline: auto;
  }
}

.form-control {
  color: rgb(104, 7, 60);
  width: 19.5em !important;
}