#techonology {
  display: block;
  margin-top: 8em;
  
  .office-img {
    height: 293px;
    width: 20rem;
    margin-top: -22px;
    margin-right: 27px;
  }
  .work-space {
    height: 293px;
    width: 17rem;
    margin-left: -145px;
    margin-top: -97px;
  }
  .tech-description{
    word-break: break-word;
    display: block;
    margin-inline: auto;
  }
}
